@keyframes moveAlongArc {
    0% {
        transform: rotate(0deg) translate(-110px) rotate(0deg);
    }
    
    15% {
        transform: rotate(54deg) translate(-110px) rotate(-54deg); /* Stop at 15% */
    }
    
    55% {
        transform: rotate(198deg) translate(-110px) rotate(-198deg); /* Stop at 55% */
    }
    
    100% {
        transform: rotate(360deg) translate(-110px) rotate(-360deg); /* Complete the arc */
    }
}