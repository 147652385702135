body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.sidebar {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  padding: 24px 16px;
}

.sidebar-item__collapsed {
  width: 80px;
  padding: 24px 16px;
}

.sidebar__collapse-button {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #a9aeb4;
  right: -10px;
  top: 16px;
  z-index: 10;
}

.sidebar-item,
.logoutbutton {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 10px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
  transition: all 0.4s ease;
}

.sidebar-item__active,
.logoutbutton {
  transform: scale(1);
  color: #4d45b5;
}

.sidebar-item__active-bg {
  position: absolute;
  inset: 0px;
  border-radius: 8px;
  background-color: #ebeaff;
}

.sidebar-item__icon,
.logoutbutton {
  position: relative;
  display: flex;
}

.sidebar-item__title,
.logoutbutton {
  position: relative;
}

.logoutbutton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

/* Ensure active state styling is consistent */
.sidebar-item__active,
.logoutbutton__active {
  transform: scale(1);
  color: #4d45b5;
}

/* Hover effect for the logout button */
.logoutbutton:hover {
  color: #4d45b5; /* Same as the general sidebar items hover color */
}

.sidebar-item__active-bg {
  position: absolute;
  inset: 0px;
  border-radius: 8px;
  background-color: #ebeaff;
}

.sidebar-item__icon,
.logoutbutton__icon {
  position: relative;
  display: flex;
}

.sidebar-item__title,
.logoutbutton__title {
  position: relative;
}
