

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');



body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--white);
    --accent: var(--black);
  

  }
  
  
 .heading {
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  

  
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: var(--background);
  }
  

  /* changed the section to section1 because it was messing
  with the css in registration page some how */
  .section1 { 
    padding-top: 85vh;
    padding-bottom: 85vh;
    position: relative;
  }
  
  
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
  
  .parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }
  
  .parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .parallax span {
    display: block;
    margin-right: 30px;
  }
  