.link-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .link-table th,
  .link-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .link-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .link-table td {
    vertical-align: top;
  }
  
  .link-table a {
    display: block;
    margin: 5px 0;
    color: #007bff;
    text-decoration: none;
  }
  
  .link-table a:hover {
    text-decoration: underline;
  }
  