div#swal2-html-container {
  width: 70vw;
}

@media screen and (max-width: 768px) {
  div#swal2-html-container {
    width: 95vw;
  }
}
div#swal2-html-container:has(> .small-container) {
  width: 50vw;
}
